<template>
  <div class="layout">
    <header class="layout__header">
      <img src="/img/logo.png" alt="Logo" class="layout__header__logo" />
      <div class="layout__header__line"></div>
    </header>

    <main class="layout__content">
      <slot></slot>
    </main>
  </div>
</template>

<style scoped lang="scss">
.layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  &__header {
    background-color: #fff;
    padding: 20px;
    text-align: center;

    &__line {
      height: 1px;
      width: 100%;
      background: #337ab7;
      margin-top: 10px;
    }
    &__logo {
      min-width: 315px;
      height: auto;
    }
  }
  &__content {
    flex: 1;
    padding: 0px;
  }
}
</style>
