import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, createVNode as _createVNode, createBlock as _createBlock, withCtx as _withCtx } from "vue"
import _imports_0 from '../assets/backArrow.svg'


const _hoisted_1 = { class: "redeem-code" }
const _hoisted_2 = {
  key: 0,
  style: {"color":"red","text-align":"center"},
  class: ""
}
const _hoisted_3 = {
  key: 1,
  class: "container"
}
const _hoisted_4 = { class: "container__back-btn" }
const _hoisted_5 = { class: "container__code" }
const _hoisted_6 = {
  key: 0,
  class: "container__error"
}
const _hoisted_7 = {
  key: 2,
  style: {"text-align":"center","margin-top":"10px"}
}

import MainLayout from "@/layouts/MainLayout.vue";
import venCodeInput from "@venegrad/vue3-code-input";
import { onMounted, ref } from "vue";
import AppButton from "@/components/AppButton.vue";
import PlaceDetails from "@/components/PlaceDetails.vue";
import { useGetCodesQuery, Code } from "../api/places";


export default /*@__PURE__*/_defineComponent({
  __name: 'RedeemCodeView',
  setup(__props) {

const vvalue = ref();
const fieldError = ref(false);
const submitted = ref(false);
const foundPlace = ref();

const { data, isLoading, error, isError } = useGetCodesQuery();

const changed = (v: string) => {
  if (v.length < 6) {
    foundPlace.value = undefined;
    return;
  }
  submit();
};

const submit = () => {
  submitted.value = true;
  const existingCodes = data.value?.map((el: Code) => el.kod);
  if (!existingCodes?.includes(parseInt(vvalue.value))) {
    fieldError.value = true;
    foundPlace.value = undefined;

    return;
  }
  const found = data.value?.find((el) => el.kod === parseInt(vvalue.value));
  foundPlace.value = found;
  fieldError.value = false;
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(MainLayout, null, {
      default: _withCtx(() => [
        (_unref(isError))
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_unref(error)), 1))
          : _createCommentVNode("", true),
        (!_unref(isLoading) && !_unref(isError))
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("img", {
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.back())),
                  src: _imports_0
                })
              ]),
              _cache[3] || (_cache[3] = _createElementVNode("div", { class: "container__title" }, [
                _createTextVNode(" Wprowadź "),
                _createElementVNode("span", { style: {"font-weight":"700"} }, "KOD PLACÓWKI"),
                _createTextVNode(" otrzymany w sekretariacie ")
              ], -1)),
              _createElementVNode("div", _hoisted_5, [
                _createVNode(_unref(venCodeInput), {
                  modelValue: vvalue.value,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((vvalue).value = $event)),
                  length: 6,
                  numbersOnly: true,
                  inFocus: true,
                  onChanged: changed
                }, null, 8, ["modelValue"])
              ]),
              ((submitted.value && vvalue.value.length !== 6) || fieldError.value)
                ? (_openBlock(), _createElementBlock("div", _hoisted_6, _cache[2] || (_cache[2] = [
                    _createTextVNode(" NIEPOPRAWNY "),
                    _createElementVNode("span", { style: {"font-weight":"700"} }, "KOD PLACÓWKI", -1)
                  ])))
                : _createCommentVNode("", true),
              (foundPlace.value && foundPlace.value.panel)
                ? (_openBlock(), _createBlock(PlaceDetails, {
                    key: 1,
                    panel: foundPlace.value.panel,
                    enabled: foundPlace.value ? true : false
                  }, null, 8, ["panel", "enabled"]))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true),
        (_unref(isLoading))
          ? (_openBlock(), _createElementBlock("div", _hoisted_7, " Ładowanie... "))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ]))
}
}

})