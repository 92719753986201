import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "" }

import MainLayout from "@/layouts/MainLayout.vue";

export default /*@__PURE__*/_defineComponent({
  __name: 'HelpView',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[2] || (_cache[2] = _createElementVNode("h1", { style: {"text-align":"center"} }, "Pomoc", -1)),
    _cache[3] || (_cache[3] = _createElementVNode("div", { style: {"background":"#e5e5e5","width":"100%","height":"480px","display":"flex","justify-content":"center","align-items":"center"} }, " Pomoc kontekstowa najlepiej w formie filmiku wideo co i jak zrobic ", -1)),
    _createElementVNode("div", {
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.back())),
      style: {"margin-left":"auto","width":"fit-content","cursor":"pointer"},
      class: ""
    }, _cache[1] || (_cache[1] = [
      _createStaticVNode("<svg width=\"74\" height=\"76\" viewBox=\"0 0 74 76\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\"><g filter=\"url(#filter0_d_801_421)\"><circle cx=\"38\" cy=\"38\" r=\"28\" fill=\"#337AB7\"></circle></g><path d=\"M45 38H31M31 38L36.25 33M31 38L36.25 43\" stroke=\"#EAEAEA\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"></path><defs><filter id=\"filter0_d_801_421\" x=\"0\" y=\"0\" width=\"76\" height=\"76\" filterUnits=\"userSpaceOnUse\" color-interpolation-filters=\"sRGB\"><feFlood flood-opacity=\"0\" result=\"BackgroundImageFix\"></feFlood><feColorMatrix in=\"SourceAlpha\" type=\"matrix\" values=\"0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0\" result=\"hardAlpha\"></feColorMatrix><feOffset></feOffset><feGaussianBlur stdDeviation=\"5\"></feGaussianBlur><feComposite in2=\"hardAlpha\" operator=\"out\"></feComposite><feColorMatrix type=\"matrix\" values=\"0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0\"></feColorMatrix><feBlend mode=\"normal\" in2=\"BackgroundImageFix\" result=\"effect1_dropShadow_801_421\"></feBlend><feBlend mode=\"normal\" in=\"SourceGraphic\" in2=\"effect1_dropShadow_801_421\" result=\"shape\"></feBlend></filter></defs></svg>", 1)
    ]))
  ]))
}
}

})