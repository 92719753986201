import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import { VueQueryPlugin } from "@tanstack/vue-query";
import mitt from "mitt";
const emitter = mitt();
createApp(App)
  .use(router)
  .use(VueQueryPlugin)
  .provide("emitter", emitter)
  .mount("#app");
