<template>
  <div class="redeem-code">
    <MainLayout>
      <template v-slot>
        <div style="color: red; text-align: center" class="" v-if="isError">
          {{ error }}
        </div>
        <div v-if="!isLoading && !isError" class="container">
          <div class="container__back-btn">
            <img @click="$router.back()" src="../assets/backArrow.svg" />
          </div>
          <div class="container__title">
            Wprowadź
            <span style="font-weight: 700">KOD PLACÓWKI</span> otrzymany w
            sekretariacie
          </div>
          <div class="container__code">
            <venCodeInput
              v-model="vvalue"
              :length="6"
              :numbersOnly="true"
              :inFocus="true"
              @changed="changed"
            />
          </div>
          <div
            v-if="(submitted && vvalue.length !== 6) || fieldError"
            class="container__error"
          >
            NIEPOPRAWNY <span style="font-weight: 700">KOD PLACÓWKI</span>
          </div>
          <PlaceDetails
            v-if="foundPlace && foundPlace.panel"
            :panel="foundPlace.panel"
            :enabled="foundPlace ? true : false"
          />
          <!-- <AppButton
            @click="submit"
            style="max-width: 400px; margin: auto; margin-top: 50px"
            :text="'Dodaj placówkę'"
            :plus="true"
          /> -->
        </div>
        <div v-if="isLoading" style="text-align: center; margin-top: 10px">
          Ładowanie...
        </div>
      </template>
    </MainLayout>
  </div>
</template>

<script lang="ts" setup>
import MainLayout from "@/layouts/MainLayout.vue";
import venCodeInput from "@venegrad/vue3-code-input";
import { onMounted, ref } from "vue";
import AppButton from "@/components/AppButton.vue";
import PlaceDetails from "@/components/PlaceDetails.vue";
import { useGetCodesQuery, Code } from "../api/places";

const vvalue = ref();
const fieldError = ref(false);
const submitted = ref(false);
const foundPlace = ref();

const { data, isLoading, error, isError } = useGetCodesQuery();

const changed = (v: string) => {
  if (v.length < 6) {
    foundPlace.value = undefined;
    return;
  }
  submit();
};

const submit = () => {
  submitted.value = true;
  const existingCodes = data.value?.map((el: Code) => el.kod);
  if (!existingCodes?.includes(parseInt(vvalue.value))) {
    fieldError.value = true;
    foundPlace.value = undefined;

    return;
  }
  const found = data.value?.find((el) => el.kod === parseInt(vvalue.value));
  foundPlace.value = found;
  fieldError.value = false;
};
</script>

<style scoped lang="scss">
.container {
  display: flex;
  flex-direction: column;
  &__code {
    padding: 0 10px;
  }
  &__title {
    font-size: 16px;
    font-weight: 400;
    padding: 20px;
    text-align: center;
    max-width: 210px;
    margin: auto;
    margin-bottom: 15px;
  }
  &__error {
    color: #ff0000;
    text-align: center;
    margin-top: 20px;
  }
  &__back-btn {
    position: absolute;
    cursor: pointer;
    top: 0;
  }
}
</style>
