<template>
  <!-- <nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </nav> -->
  <router-view />
</template>

<style>
@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap");
#app {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
}

.code-field__list {
  display: flex !important;
  gap: 10px;
  justify-content: center;
  align-items: center;
}

.code-field__input {
  height: 42px !important;
  max-width: 41px !important;
  margin: auto;
  text-align: center;
  border-radius: 6px;
  border: 1px solid #888888;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>
